// Add ES6 polyfill.
// Makes the latest javascript features available in older browsers.
// Must be loaded in order, hence the `require()` instead of `import`.
import "core-js/stable";
import "regenerator-runtime/runtime";
import('./render')


// Service Worker'i kaydet
if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register(
      new URL('/public/service-worker.js', import.meta.url),
      {type: 'module'}
    ).then(() => {
         console.log("Service Worker Registered!");
       });
};
//  navigator.serviceWorker.register("/service-worker.js").then(() => {
//    console.log("Service Worker Registered!");
//  });
//}
